<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-input :value="regionName" readonly placeholder="土地行政区划" style="width: 280px;">
                        <template #append>
                            <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                    <el-select v-model="warningDays" placeholder="预警天数" class="ml-2" style="width: 120px;"
                        @change="loadLands(true)">
                        <el-option :value="3" label="3 天" />
                        <el-option :value="10" label="10 天" />
                        <el-option :value="30" label="30 天" />
                    </el-select>
                    <search-box v-model="key" class="ml-2" style="width: 280px;" @search="loadLands(true)" />
                </div>
            </div>
            <el-table :data="lands.data" class="mt-2">
                <el-table-column label="名称/编号" sortable>
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="类型" sortable width="160" />
                <el-table-column prop="regionName" label="土地行政区划" sortable width="160" />
                <el-table-column prop="area" label="面积" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="lands.totalRecords"
                :current-page="landsPagination.currentPage" :page-size="landsPagination.pageSize" class="mt-3"
                @current-change="landsPaginationCurrentChange" />
        </el-card>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="regionId=$event.id;regionName=$event.name;loadLands(true)" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                regionId: null,
                regionName: null,
                warningDays: 10,
                key: null,
                lands: {},
                landsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                selectRegionDialogVisible: false,
            };
        },
        methods: {
            async loadLands(resetPage = false) {
                if (resetPage) {
                    this.landsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Land/GetWarningLands', {
                    params: {
                        companyId: this.$store.state.companyId,
                        regionId: this.regionId,
                        warningDays: this.warningDays,
                        key: this.key,
                        pageIndex: this.landsPagination.currentPage - 1,
                        pageSize: this.landsPagination.pageSize,
                    }
                });
                this.lands = response.data;
            },
            landsPaginationCurrentChange(page) {
                this.landsPagination.currentPage = page;
                this.loadLands();
            },
        },
        created() {
            this.loadLands();
        },
    };
</script>